.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 0 20px;
  z-index: 100;
}

.social-media-icons {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50px; 
  right: 80px; 
}

.social-media-icon {
  color: black;
  font-size: 35px;
  margin-left: 15px; 
  transition: color 0.3s ease; 
}

.social-media-icon:hover {
  color: #324aa8; 
}

.social-media-icon:focus {
  outline: none; 
}

.phone-number {
  font-size: 24px;
  position: absolute;
  top: 50px;
  left: 35px;
  display: flex;
  align-items: center;
  color: black;
}

.phone-number .fa-phone {
  margin-right: 8px;
}

.navbar-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.nav-button {
  background-color: transparent;
  border: none;
  color: black;
  font-size: 24px;
  cursor: pointer;
  padding: 10px 20px;
  text-decoration: none;
  position: relative;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-button-active {
  background-color: transparent;
  border: none;
  color: red;
  font-size: 24px;
  cursor: pointer;
  padding: 10px 20px;
  text-decoration: none;
  position: relative;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-button-active:hover {
  border-bottom: 4px solid red;
  transition: all 0.2s ease-out;
  color: red;
}

.menu-dropdown {
  position: relative;
  display: inline-block;
  
}


.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.menu-dropdown:hover{
  border-bottom: 4px solid blue;
  transition: all 0.2s ease-out;
}

.menu-dropdown:hover .dropdown-content {
  display: block;
  
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  color: #324aa8;
  border-bottom: 4px solid blue;
  transition: all 0.2s ease-out;
}

.logo {
  height: 100px;
  cursor: pointer;
}

.logo-link {
  display: flex;
  align-items: center;
}

.nav-button:hover {
  border-bottom: 4px solid #324aa8;
  transition: all 0.2s ease-out;
  color: #324aa8;
}

.fa-bars, .fa-times {
  color: black;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {

  .social-media-icons {
    top: 15px;
    right: 70px;
    position: absolute;
  }
  
  .navbar-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }

  .phone-number {
    font-size: 24px;
    position: absolute;
    top: 20px;
    left: 25px;
    
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 0;
    position: fixed;
    top: 120px;
    left: 0;
    transform: translateX(-100%);
    opacity: 0;
    transition: all 0.5s ease;
    background: white;
    overflow-y: hidden;
  }
  .navbar-center {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0;
  }

  .nav-menu.active {
    transform: translateX(0);
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    height: 90vh;
    display: flex;
  }

  .nav-button {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: block;
  }

  .nav-button:hover {
    background-color: #fff;
    color: #324aa8;
    border-radius: 0;
  }

  .dropdown-content {
    position: static;
    width: 100%;
  }

  .dropdown-content a {
    width: 100%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translate(0, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: black;
    font-size: 2rem;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: block;
  }

  .logo {
    height: none;
  }
}

@media screen and (max-width: 400px) {
  .social-media-icons {
    top: 5px;
    right: 70px;
    font-size: 20px;
    position: absolute;
  }

  .phone-number {
    font-size: 20px;
    position: absolute;
    top: 18px;
    left: 1px;
  }

  .navbar {
    height: 100px;
  }
  .menu-icon{
    bottom: 1px;
    top: 10px;
  }
}

