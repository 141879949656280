@keyframes zoomIn {
    from {
        transform: scale(0.1);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.about-page {
    padding: 20px;
}

.header {
    display: flex;
    flex-wrap: wrap;
}

.left-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.restaurant-info, .inside-pic {
    margin-bottom: 20px;
}

.inside-pic img {
    width: 100%;
    max-width: 600px;
    height: auto;
}

.restaurant-info p {
    font-size: 20px;
    text-align: center;
}

.restaurant-image img {
    width: 100%;
    max-width: 600px;
    height: auto;
}

.restaurant-image {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.photo-gallery {
    margin-top: 50px;
    text-align: center;
}

.photo-gallery h2 {
    padding-bottom: 20px;
    font-size: 28px;
}

.photos {
    cursor: pointer;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s, visibility 0.5s;
}

.overlay img {
    max-width: 80%;
    max-height: 80%;
    border: 1px solid black;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    animation: zoomIn 0.5s forwards;
}

.overlay .close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
}

.overlay .prev, .overlay .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    color: red;
    cursor: pointer;
}

.overlay .prev {
    left: 15px;
}

.overlay .next {
    right: 15px;
}

@media screen and (min-width: 800px) {
    .photos {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 960px) {
    .header {
        flex-direction: column;
        align-items: center;
    }

    .restaurant-image img {
        max-width: 600px;
    }

    .photos {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
}
