  .intro-section {
    display: flex;
    align-items: center;
    padding: 20px;
    flex-wrap: wrap;
  }
  
  .intro-image {
    width: 100%;
    max-width: 800px;
    height: auto;
    object-fit: cover;
    margin-right: 20px;
  }
  
  .intro-text {
    flex: 1;
  }
  
  .intro-text h1 {
    font-size: 36px;
    margin: 0;
    text-align: center;
  }
  
  .intro-text h2 {
    font-size: 24px;
    margin: 10px 0;
    text-align: center;
  }
  
  .intro-text p {
    padding-top: 20px;
    font-size: 18px;
    text-align: center;
  }
  
  .signup-section {
    margin-top: 40px;
    text-align: center;
    justify-content: center;
  }

  .form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ctct-inline-form{
    width: 50%;
  }
  
  .email-input {
    padding: 10px;
    font-size: 16px;
    margin-right: 10px;
  }
  
  .signup-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .images-section {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    flex-wrap: wrap;
    padding: 20px;
  }
  
  .grid-image {
    width: 23%;
    height: auto;
    margin-bottom: 20px;
    object-fit: cover;
  }
  
  .reviews-section {
    margin-top: 40px;
    text-align: center;
  }
  
  .location-section {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    flex-wrap: wrap;
    padding: 30px;
    background-color: #f4f4f4;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    background-clip: content-box;
  }
  
  .location-info, .hours-info {
    flex: 1;
    padding: 20px;
  }
  
  .location-info h4, .hours-info h4 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .location-info p, .hours-info p {
    font-size: 16px;
    margin: 5px 0;
  }

  .award-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f4f4f4;
    max-width: 700px;
    margin: 0 auto;
  }
  
  .images {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .images img {
    max-width: 80%;
    max-height: 100px;
    border-radius: 8px;
  }
  
  .thank-you-message {
    font-size: 20px;
    color: #333;
    text-align: center;
  }

  .thank-you-message a {
    color: #007bff;
    text-decoration: none;
  }
  
  .thank-you-message a:hover {
    text-decoration: underline;
  }
  

  @media (max-width: 1200px) {
    .intro-section {
      flex-direction: column;
      align-items: center;
    }
  
    .intro-image {
      width: 100%;
      max-width: none; 
      margin: 0 0 20px 0;
    }
  
    .images-section {
      justify-content: center; 
    }
  
    .grid-image {
      width: 45%; 
    }
  
    .location-section {
      width: 90%; 
      padding: 20px; 
    }

    .award-section {
      padding: 10px;
    }
  
    .images {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  
    .images img {
      max-width: 250px;
    }
  
    .thank-you-message {
      font-size: 18px;
      padding: 0 10px;
    }
  }
  