.footer {
    height: 180px;
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    background-clip: content-box;
    box-sizing: border-box; 
}

.footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1850px;
}

.footer-left {
    display: flex;
    flex-direction: column;
    padding: 10px; 
}

.footer-left h2,
.footer-left p {
    margin: 1px 0;
}

.footer-right {
    display: flex;
    align-items: center;
    padding: 10px; 
}

.footer-logo {
    height: 100%;
    max-height: 160px; 
    max-width: 100%;
    padding: 10px;
}

@media screen and (max-width: 960px) {
    .footer-left h2,
    .footer-left p {
        font-size: 12px;
    }   
    .footer-logo {
        height: 80%;
        max-height: 120px; 
        max-width: 100%;
    }
    .footer{
        height: 155px;
    }
}